header {
    padding: 20px 0 0;
    @include mq('desktop') {
        padding: 40px 0 30px;
    }
    h1 {
        text-transform: uppercase;
        letter-spacing: -0.05em;
        @include font-size(15);
        @include mq('mobile') {
            @include font-size(26);
            position: relative;
            top: 15px;
            transform: translateY(-50%);
            float: right;
        }
        @include mq('desktop') {
            float: right;
        }
    }
    img {
        display: block;
        height: 60px;
        @include mq('mobile') {
            float: left;
            height: 80px;
        }
    }
    nav {
        clear: both;
        padding: 20px 0 0;
    }
}

.indicator-row {
    display: table;
    width: 100%;
    margin-bottom: 20px;
    div {
        display: table-cell;
        border-right: 1px solid $white;
        height: 10px;
    }
    .page-complete {
        background-color: $grey;
    }
    .page-completing {
        background-color: $dark-grey;
    }
    .page-incomplete {
        background-color: $light-grey;
    }
}
