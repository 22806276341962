// Loading spinner
.loader {
    display: block;

    // Disabled the below for styleguide positioning purposes
    //position: fixed;
    //top: 50%;
    //margin-top: -66px; // height + borders / 2
    //left: 50%;
    //margin-left: -66px; // width + borders / 2

    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid rgb(137, 156, 28);
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.loader.small {
    width: 60px;
    height: 60px;
}

.loader.exsmall {
    width: 30px;
    height: 30px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// CSSTransitionGroup fade for pre-qualifiers
.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.fade-appear {
    opacity: 0.01;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.slide-left-enter {
    opacity: 0.01;
    transform: translate3d(100px, 0, 0);
}

.slide-left-enter.slide-left-enter-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: all 1000ms ease;
}

.slide-left-leave {
    transform: translate3d(100px, 0, 0);
    opacity: 1;
}

.slide-left-leave.slide-left-leave-active {
    transform: translate3d(100px, 0, 0);
    opacity: 0.01;
    transition: all 1000ms ease;
}

.slide-left-appear {
    opacity: 0.01;
    transform: translate3d(100px, 0, 0);
}

.slide-left-appear.slide-left-appear-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: all 1000ms ease;
}

.slide-right-enter {
    opacity: 0.01;
    transform: translate3d(-100px, 0, 0);
}

.slide-right-enter.slide-right-enter-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: all 1000ms ease;
}

.slide-right-leave {
    transform: translate3d(-100px, 0, 0);
    opacity: 1;
}

.slide-right-leave.slide-right-leave-active {
    transform: translate3d(-100px, 0, 0);
    opacity: 0.01;
    transition: all 1000ms ease;
}

.slide-right-appear {
    opacity: 0.01;
    transform: translate3d(-100px, 0, 0);
}

.slide-right-appear.slide-right-appear-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: all 1000ms ease;
}

// Information expandable/collapsible
.slide-out {
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all 500ms ease-out;
}

.slide-in {
    max-height: 2200px;
    opacity: 1;
    transition: all 500ms ease-in;
}
