/**
 * Toolkit styles
 */

.gotham-light {
    font-family: $gotham;
    font-weight: 300;
}

.gotham-book {
    font-family: $gotham;
    font-weight: 400;
}

.gotham-bold {
    font-family: $gotham;
    font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    margin: 1em 0;
    font-family: $gotham;
    font-weight: 300;
    letter-spacing: -0.025em;
    strong {
        font-weight: 700;
    }
}

h1 {
    @include font-size(28);
}

h2 {
    @include font-size(24);
}

h3 {
    @include font-size(18);
    font-weight: 700;
}

h4 {
    @include font-size(16);
}

h1[class^='border-bottom'],
h2[class^='border-bottom'],
h3[class^='border-bottom'],
h4[class^='border-bottom'],
h5[class^='border-bottom'],
h6[class^='border-bottom'] {
    padding-bottom: 0.5em;
}

h1[class^='border-top'],
h2[class^='border-top'],
h3[class^='border-top'],
h4[class^='border-top'],
h5[class^='border-top'],
h6[class^='border-top'] {
    padding-top: 0.5em;
}

fieldset h2 {
    font-weight: 700;
    @include font-size(18);
}

fieldset .question h2,
fieldset.height_and_weight_wrapper h2,
fieldset.tell_us_more_wrapper h2 {
    font-weight: 300;
    @include font-size(24);
}

fieldset:first-of-type > h2 {
    @include font-size(24);
    font-weight: 700;
    color: $white-on-green;
    text-transform: uppercase;
    margin-top: 0;
}

body {
    font-family: $gotham, sans-serif;
    @include font-size(16);
}

p {
    @include font-size(16);
    margin: 1em 0;
}

p:first-of-type {
    margin-top: 0;
}

.small {
    @include font-size(12);
}
