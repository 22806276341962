ul {
    box-sizing: border-box;
    margin: 1em 0;
    padding-left: 1em;
    li {
        margin: 0.5em 0;
    }
    &.list--arrows {
        padding-left: 2em;
        > li {
            position: relative;
            list-style: none;
            margin: 1em 0;
            &:before {
                content: url("data:image/svg+xml; utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid meet' viewBox='0 0 50 50' width='20' height='20'%3E%3Cdefs%3E%3Cpath d='M18.81 39.53L33.83 24.52L18.81 9.51' id='cda35YEiY'%3E%3C/path%3E%3C/defs%3E%3Cuse xlink:href='%23cda35YEiY' opacity='1' fill-opacity='0' stroke='%23899c1c' stroke-width='6' stroke-opacity='1'%3E%3C/use%3E%3C/svg%3E");
                display: block;
                height: 20px;
                width: 20px;
                left: -2em;
                top: 0.05em;
                position: absolute;
            }
        }
    }
    &.list--blocks {
        padding: 0;
        > li {
            box-sizing: border-box;
            list-style: none;
            background-color: $grey;
            padding: 15px;
        }
    }
}