/**
 * Form styles
 */

.disabled {
    color: $grey;
}

fieldset {
    margin: 1em 0;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    padding: 12px 15px;
    @include font-size(16);
    background-color: $light-grey;
    border: 1px solid $grey;
    border-radius: 0;
    margin: 0.5em 0 0.75em;
}

input[type='text'],
select {
    height: 50px;
}

select {
    /*
    Bootstrap approach, using custom inline SVG background image: https://v4-alpha.getbootstrap.com/components/forms/#select-menu
    Not using a pseudo element here (unlike for buttons) as the select element does not allow them and therefore an additional wrapper element would be necessary: http://stackoverflow.com/questions/21103542/pseudo-elements-and-select-tag)
    SVG content must be url-encoded (spaces allowed though): https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
    SVG polygons can be converted to a path by appending 'M' and prepending 'z': http://stackoverflow.com/questions/13679495/examples-of-polygons-drawn-by-path-vs-polygon-in-svg
    */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 21'%3E%3Cpath d='M20 21 0 1 1 0 20 19 39 0 40 1 20 21z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 30px auto;
    padding-right: 45px;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='tel']:focus,
input[type='number']:focus,
select:focus,
textarea:focus {
    border: 1px solid $white-on-green;
    outline: none;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
}

label:not(.col):not(.control),
.radio .label {
    font-weight: 700;
    color: $dark-grey;
    clear: both;
    display: block;
    margin-bottom: 10px;
}

/* Custom-styled checkboxes and radio buttons
  Chosen Bootstrap approach is to style using an additional markup div - 'control__indicator': https://v4-alpha.getbootstrap.com/components/forms/#checkboxes
  Gov.uk approach is to use Javascript: http://govuk-elements.herokuapp.com/form-elements/#form-checkboxes
*/
input[type='checkbox'],
input[type='radio'] {
    margin: 0;
}

.control {
    @include font-size(16);
    position: relative;
    display: inline-flex;
    align-items: center;
    clear: both;
    margin-bottom: 15px;
    margin-right: 15px;
    padding-left: 55px;
    cursor: pointer;
    min-height: 40px;
}

.control--small {
    @include font-size(14);
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;
    min-height: 20px;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    left: 0;
}

.control__indicator {
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    background: $light-grey;
    border: 1px solid $grey;
}

.control__indicator--small {
    width: 20px;
    height: 20px;
    margin-top: -10px;
}

.control--radio .control__indicator {
    border-radius: 50%;
}

/* Hover state */
.control:hover input ~ .control__indicator {
    // background: $light-grey;
}

/* Focus state */
.control input:focus ~ .control__indicator {
    background: $light-grey;
    border: 1px solid $white-on-green;
}

/* Checked state */
.control input:checked ~ .control__indicator {
    // background: $grey;
}

/* Hover state whilst checked */
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
    background: $light-grey;
}

/* Disabled state */
.control input:disabled ~ .control__indicator {
    pointer-events: none;
    opacity: .6;
    background: $grey;
}

/* Check mark */
.control__indicator:after {
    position: absolute;
    display: none;
    content: '';
}

/* Show check mark */
.control input:checked ~ .control__indicator:after {
    display: block;
}

.control input:checked ~ .control__indicator:after {
    display: block;
}

/* Checkbox tick */
.control--checkbox .control__indicator:after {
    top: 4px;
    left: 14px;
    width: 9px;
    height: 24px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: solid $white-on-green;
    border-width: 0 4px 4px 0;
}

.control--checkbox .control__indicator--small:after {
    top: 2px;
    left: 7px;
    width: 4px;
    height: 12px;
    border-width: 0 2px 2px 0;
}

/* Disabled tick colour */
.control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
}

/* Radio button inner circle */
.control--radio .control__indicator:after {
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $white-on-green;
}

.control--radio .control__indicator--small:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
}

/* Disabled circle colour */
.control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
}

/* Yes/No style radio buttons */
.yes-no {
    display: inline-flex;
    width: 130px;
    @include mq('desktop') {
        width: 150px;
    }
    min-height: 48px;
    margin-right: 25px;
    @include font-size(18);
    .control__indicator {
        display: none;
    }
}

/* Error styles */
input[type='text'].error,
input[type='email'].error,
input[type='tel'].error,
input[type='number'].error,
select.error,
textarea.error,
.control__indicator.error {
    border: 1px solid #e36060;
    background-color: #fae2e2;
}

.error-message {
    display: block;
    margin: -5px 0 1em;
    @include font-size(14);
    font-style: italic;
    font-weight: 400;
    color: #e36060;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.inactive {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.active {
    opacity: 1;
    height: auto;
    transition: opacity 1000ms ease-in;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 1em 0;
    .question {
        h2 {
            display: inline-block;
            padding: 0;
            margin: 0 0 0.5em;
            line-height: 1.4em;
            &.completed {
                color: $dark-grey;
            }
        }
        span:nth-of-type(2) label {
            margin-right: 0;
        }
        .span_2_of_12 {
            float: right;
        }
    }
}

.answer {
    text-transform: uppercase;
    text-align: center;
    padding: 5px;
    background-color: $dark-grey;
    color: $white;
    margin-bottom: 0.5em;
    span {
        font-size: 1em;
        font-family: $gotham;
        font-weight: 300;
        letter-spacing: -0.05em;
        &:last-child {
            padding: 0 0 0 10px;
        }
        @include mq('mobile') {
            &:first-child {
                font-size: .5em;
                line-height: 2em;
            }
            &:last-child {
                padding: 0;
            }
            font-size: 1.2em;
            display: block;
            line-height: 1em
        }
    }
    &.failed {
        background-color: $black;
    }
}

// Webforms-specific styles below disabled for styleguide
// @TODO move these styles into app-specific styles/css files
//
//// Height & Weight
//
//.height_and_weight_wrapper {
//    h3 {
//        display: block;
//        clear: both;
//    }
//    label {
//        position: relative;
//    }
//    .units_for_weight, .units_for_height {
//        clear: both;
//    }
//    @include mq('desktop') {
//        .warn-message {
//            font-weight: 700;
//            position: absolute;
//            top: 22px;
//            right: -270px;
//            width: 250px;
//        }
//    }
//}
//
//.bmi-fail-wrapper, .weight-fail-wrapper {
//    display: none;
//}
//
//// Gender
//
//.gender {
//    h2 {
//        margin-bottom: 0.5em;
//    }
//    .control {
//        width: 80px;
//        height: 80px;
//        padding-left: 0;
//        margin-bottom: 5px;
//        span {
//            position: absolute;
//            z-index: 1;
//            bottom: 5px;
//            display: block;
//            width: 100%;
//            @include font-size(12);
//            text-align: center;
//        }
//    }
//    .control__indicator {
//        top: 0;
//        margin-top: 0;
//        border-radius: 50%;
//        width: 80px;
//        height: 80px;
//        &:after {
//            width: 80px;
//            height: 80px;
//            top: 0;
//            left: 0;
//            border-radius: 50%;
//        }
//    }
//    label .selected {
//        color: $white;
//    }
//    // using external svg files here as could not get inline svg to display as background images
//    input[value='male'] + span + .control__indicator {
//        background-image: url('../images/gender-icon-male.svg');
//        background-repeat: no-repeat;
//        background-position: center 7px;
//        background-size: 45%;
//        &:after {
//            background-image: url('../images/gender-icon-male-white.svg');
//            background-repeat: no-repeat;
//            background-position: center 7px;
//            background-size: 45%;
//            background-color: $white-on-green;
//        }
//    }
//    input[value='female'] + span + .control__indicator {
//        background-image: url('../images/gender-icon-female.svg');
//        background-repeat: no-repeat;
//        background-position: center 7px;
//        background-size: 45%;
//        &:after {
//            background-image: url('../images/gender-icon-female-white.svg');
//            background-repeat: no-repeat;
//            background-position: center 7px;
//            background-size: 45%;
//            background-color: $white-on-green;
//        }
//    }
//    .error-message {
//        display: block;
//        margin-bottom: 0.5em;
//    }
//}
//
//.visuallyhidden {
//    border: 0;
//    clip: rect(0 0 0 0);
//    height: 1px;
//    margin: -1px;
//    overflow: hidden;
//    padding: 0;
//    position: absolute;
//    width: 1px;
//}
//
//// Ethnicity
//
//.ethnicity_wrapper {
//    label {
//        width: 100%;
//    }
//    label .selected {
//        font-weight: 700;
//    }
//    .ethnicity-helpers {
//        position: relative;
//        padding-left: 30px;
//        strong {
//            display: block;
//            margin: 0 0 0.5em;
//        }
//        .back {
//            position: absolute;
//            top: -3px;
//            left: 8px;
//            text-decoration: none;
//        }
//        .back:before {
//            position: absolute;
//            border-style: solid;
//            border-width: 0 0 2px 2px;
//            content: '';
//            display: inline-block;
//            width: 16px;
//            height: 16px;
//            top: 50%;
//            margin-top: -8px;
//            left: 0;
//            -webkit-transform: rotate(45deg);
//            transform: rotate(45deg);
//            vertical-align: middle;
//            color: $white-on-green;
//        }
//    }
//}
//
//// Address
//
//.address-link {
//    display: block;
//    margin: 0.5em 0 0.75em;
//    padding: 12px 15px 22px;
//}
//
//.address-link-show {
//    padding: 12px 0 22px;
//    margin: 0;
//}
//
//// Medical conditions
//
//.blood_transfusion_wrapper {
//    > div {
//        clear: both;
//    }
//}
//
//.content--fail {
//    h2 {
//        line-height: 1.2em;
//    }
//}
//
//.medical_conditions_wrapper {
//    h3 {
//        margin: 2px 0 0 0;
//    }
//    .medical-condition {
//        padding: 0;
//        margin-bottom: 3px;
//        background-color: $grey;
//        .control--small {
//            width: 100%;
//            box-sizing: border-box;
//            margin: 0;
//            padding: 15px 15px 15px 45px;
//            font-size: 1.6rem;
//        }
//        .control__indicator--small {
//            left: 15px;
//        }
//    }
//}
//
//.condition-questions {
//    padding: 0 12px;
//    .span_12_of_12 {
//        clear: both;
//    }
//    @include mq('desktop') {
//        .ongoing {
//            float: right;
//            div label:nth-child(2) {
//                margin-right: 0;
//            }
//        }
//    }
//
//}
//
//// Consent
//
//.understood_statements,
//.give_consent {
//    label {
//        border: 1px solid $white-on-green;
//        padding: 20px 20px 20px 80px;
//    }
//    .control__indicator {
//        left: 20px;
//    }
//}
