//APPDEV-343 - Events search results
.events-search-results {
    padding: 0 5.5%;
    @media screen and (min-width: 575px) {
        padding: 0;
    }
}
.events-search-results li {
    margin-bottom: 10px;
    @media screen and (min-width: 868px) {
        float: left;
    }
}

.events-search-results article {
    overflow: hidden;
    border-top: 1px #d6d6d6 solid;
    p {
        margin: 0;
    }
}

.events-search {
    background-color: #ececec;
    padding: 15px;
    margin: 20px 0;
    .search-field {
        margin: 0 0 20px;
    }
    label {
        font-size: 1.6em;
        border-bottom: none;
        padding: 0;
        margin: 0 0 5px;
    }
}

.teaser {
    position: relative;
    margin: 0 0 10px;
}

.teaser__image {
    @media screen and (min-width: 868px) {
        float: left;
    }
}

.teaser__info {
    @media screen and (min-width: 868px) {
        float: left;
        margin-left: 3.06%;
    }
    h2 {
        line-height: 1.2;
        border: 0;
        margin: 0.5em 0;
        padding: 0;
        width: 100%;
        a {
            color: #000;
        }
    }
    p {
        font-size: 14px;
    }
}

.teaser__description {
    margin-bottom: 1em;
    p {
        padding: 0;
        width: 100%;
    }
}

.teaser__links {
    clear: both;
    padding: 10px 0;
    a {
        display: block;
        color: #fff;
        font-size: 14px;
        text-align: center;
        padding: 5px 20px;
        background-color: #899c1c;
        max-width: 80px;
        }
    }

.teaser__top-pick {
    position: absolute;
    top: 0;
    left: 0;
}
//End events search results
