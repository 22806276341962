img {
    max-width: 100%;
    height: auto;
}

// Full page responsive background image
.bg-img {
    /* Set rules to fill background */
    min-height: 100%;

    /* Set up proportionate scaling */
    width: 100%;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.video-container {
    position: relative;
    height: 0;
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

/* 16x9 Aspect Ratio */
.video-container-16x9 {
    padding-bottom: 56.25%;
}

/* 4x3 Aspect Ratio */
.video-container-4x3 {
    padding-bottom: 75%;
}