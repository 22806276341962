/**
 * Button styles for calls to actions and forms
 */

button,
.btn {
    box-sizing: border-box;
    position: relative;
    display: block;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    border: none;

    font-family: $gotham;
    font-weight: 300;
    letter-spacing: -0.03em;
    text-decoration: none;
    text-transform: uppercase;
    text-align: left;
    transition: 0.3s all ease-in-out;
    cursor: pointer;

    // Default button style
    min-height: 48px;
    margin: 0.5em 0;
    padding: 10px 15px;
    @include font-size(13);

    @include mq('mobile') {
        @include font-size(18);
    }
}

.btn--primary {
    color: $white;
    background-color: $white-on-green;
}

.btn--primary:hover {
    background-color: darken($white-on-green, 10%);
    text-decoration: none;
}

.btn--secondary {
    color: $white;
    background-color: $green-on-white;
}

.btn--secondary:hover {
    background-color: darken($green-on-white, 10%);
    text-decoration: none;
}

.btn--tertiary {
    color: $black;
    background-color: $grey;
}

.btn--tertiary:hover {
    background-color: darken($grey, 10%);
    text-decoration: none;
}

.right-arrow::before {
    position: absolute;
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -8px;
    right: 15px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    vertical-align: middle;
}

.left-arrow::before {
    position: absolute;
    border-style: solid;
    border-width: 0 0 2px 2px;
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -8px;
    left: 15px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    vertical-align: middle;
}

.btn--large {
    padding: 10px 15px;
    @include mq('mobile') {
        font-size: 3vw;
        padding: 25px;
        min-height: 105px;
        margin: 1em 0;
    }
    @include mq('desktop') {
        font-size: 32px;
    }
    &.right-arrow {
        padding: 10px 35px 10px 15px;
        @include mq('mobile') {
            padding: 25px 70px 25px 25px;
        }
    }
    &.right-arrow::before {
        @include mq('mobile') {
            width: 50px;
            height: 50px;
            margin-top: -25px;
            right: 30px;
        }
    }
}

.btn--medium {
    min-height: 48px;
    margin: 10px 0;
    padding: 10px 15px;
    @include font-size(18);
    &.right-arrow {
        padding: 10px 35px 10px 15px;
    }
    &.left-arrow {
        padding: 10px 15px 10px 35px;
    }
}

.btn--small {
    min-height: 36px;
    margin: 10px 0;
    padding: 10px 15px;
    @include font-size(13);
    text-transform: none;
}

.btn--inline {
    display: inline-block;
    margin-right: 10px;
}
