/**
 * Borders for text headings and content block elements
 */

.border-top-dashed {
    border-top: 1px dashed $dark-grey;
}

.border-top-solid {
    border-top: 1px solid $grey;
}

.border-bottom-dashed {
    border-bottom: 1px dashed $dark-grey;
}

.border-bottom-solid {
    border-bottom: 1px solid $grey;
}
