// Import Anthony Nolan Styleguide
@import '../styleguide/toolkit.scss';

body {
    margin: 0;
}

.group {
    position: static;
}

.content {
    position: relative;
}

.section {
    @include clearfix();
}

@include mq('mobile') {
    .span_4_of_12 {
        &:nth-child(3n + 1) {
            margin-left: 0;
            clear: both;
        }
    }
}

h2:first-of-type {
    margin-top: 0;
}

button,
.btn {
    border-radius: 0;
}
.btn:focus {
    outline: none;
}

.error-message {
    margin: 0.5em 0;
    text-align: center;
}
aside .error-message,
.po-number .error-message {
    text-align: left;
}

.po-number input[type='text'] + .error-message {
    margin: 0 0 1em;
}

header {
    padding: 0;
    h1 {
        @include font-size(24);
        transform: none;
        top: 0;
        margin: 1.6em 0;
        float: none;
        @include mq('tablet') {
            @include font-size(32);
            float: right;
        }
    }
    img {
        margin: 30px 0;
    }
}

.nav {
    border-left: 1px solid $grey;
    border-right: 1px solid $grey;
    border-bottom: 1px solid $grey;
}

.nav-block {
    padding: 5px;
    text-align: center;
    @include font-size(12);
    div {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.menu {
    float: left;
}

.user-profile {
    float: right;
    border-left: 1px solid $grey;
    width: 350px;
}

.indicator-row {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 40px;
    div {
        display: table-cell;
        padding: 0 2px;
        height: auto;
        border-right: none;
    }
    .page-complete,
    .page-completing,
    .page-incomplete {
        background: none;
        span {
            display: block;
            height: 10px;
            margin-bottom: 5px;
        }
    }
    .page-complete span {
        background-color: $mid-grey;
    }
    .page-completing {
        font-weight: 700;
        span {
            background-color: $white-on-green;
        }
    }
    .page-incomplete span {
        background-color: $grey;
    }
}

table {
    width: 100%;
    border: 1px solid $grey;
    border-spacing: 0;
    padding: 20px;
}
form table {
    margin-bottom: 30px;
}
tr:nth-child(odd) {
    background-color: $light-grey;
}
th {
    font-weight: 700;
    text-align: left;
}
th,
td {
    padding: 5px;
}

.dot:before {
    content: '';
    display: block;
    float: left;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 3px;
    border-radius: 50%;
}
.dot--red:before {
    background: red;
}
.dot--yellow:before {
    background: yellow;
}
.dot--blue:before {
    background: blue;
}
.dot--none:before {
    background: $mid-grey;
}

aside {
    > div {
        border-left: 1px solid $grey;
        padding-left: 30px;
    }
    h3:first-of-type {
        margin-top: 0;
    }
    table {
        margin-bottom: 30px;
    }
}

form {
    padding: 30px;
    border: 1px solid $grey;
}

form h2 {
    text-align: center;
    border-bottom: 1px solid $grey;
    padding-bottom: 10px;
}

label {
    h3 {
        color: $black;
    }
}

.down-arrow {
    position: relative;
    height: 50px;
    margin: 30px 0;
    &:before {
        position: absolute;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: $white-on-green;
        content: '';
        display: inline-block;
        width: 50px;
        height: 50px;
        top: -18px;
        left: 50%;
        margin-left: -25px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        vertical-align: middle;
    }
}

.btn-actions {
    text-align: center;
    .btn {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.btn--dispatch-boxes {
    position: absolute;
    top: 12px;
    right: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.95);
    z-index: 1;
    .popup {
        position: relative;
        max-width: 500px;
        background: $white;
        padding: 30px;
        border: 1px solid $grey;
        text-align: center;
        box-shadow: 0 0 20px $mid-grey;
    }
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    cursor: pointer;
    margin: 0;
    padding: 0;
    min-height: 0;
}
.close:hover {
    opacity: 1;
}
.close:before,
.close:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #333;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

input:disabled,
button:disabled {
    opacity: 0.35;
    cursor: default;
}

.loader {
    margin: 0 auto;
}

.loader--btn {
    float: right;
    width: 10px;
    height: 10px;
    border-width: 3px;
    margin: 5px 0 0 10px;
}

.loader--table {
    width: 10px;
    height: 10px;
    border-width: 3px;
    vertical-align: middle;
}
.btn--small .loader--btn {
    border-width: 2px;
    margin: 3px 0 0 10px;
}

.box {
    margin: 0 0 1.5em;
}

.box-create {
    text-align: center;
}

.box-label {
    box-sizing: border-box;
    border: 1px solid $grey;
    padding: 20px;
    margin-bottom: 20px;
    &:nth-of-type(2) {
        display: none;
    }
    img {
        display: block;
        width: 250px;
        margin: 10px auto 20px;
    }
}

.control--checkbox {
    vertical-align: center;
    margin: 0;
}
.csv-table,
.box-table,
.dot-table {
    position: relative;
    margin-bottom: 2em;
    @include font-size(14);
    thead tr {
        background: none;
    }
    th,
    td {
        padding: 10px;
    }
    thead h2 {
        text-align: center;
        border-bottom: 1px solid $grey;
        padding-bottom: 10px;
        margin-bottom: 0;
    }
    tr {
        text-align: left;
    }

    .box-actions {
        width: 100px;
        text-align: center;
        .btn--inline {
            min-width: 100px;
            margin: 5px;
            text-align: center;
            flex-direction: row;
        }
    }
    .box-actions--row {
        width: 300px;
    }
    tfoot td {
        padding: 0;
    }
}

.box-search {
    h2 {
        text-align: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
    }
    label {
        text-align: center;
    }
    table {
        border-top: 0;
    }
}

// Hide/modify elements for printing box labels
// All App elements are hidden by default (overlays, including the Box label overlay are rendered in a separate React tree - see https://github.com/tajo/react-portal)
@media print {
    .App,
    .no-print,
    h2,
    .btn {
        display: none;
    }
    .box-create {
        border: none;
        h2 {
            display: none;
        }
    }
    .box-label-container {
        position: relative;
        height: 95vh;
    }
    .box-label {
        position: absolute;
        top: 4%;
        height: 40%;
        width: 100%;
        margin: 0;
        border-color: $mid-grey;
        &:nth-of-type(2) {
            display: block;
            top: auto;
            bottom: 0;
        }
        img {
            margin: 30px auto 40px;
        }
    }
    .overlay {
        position: static;
        .popup {
            position: static;
            max-width: none;
            border: none;
            padding: 0;
            width: 100%;
        }
    }
}

// Date picker
.date-selector {
    margin-top: 1em;
    text-align: center;
}
.DateRangePicker {
    margin: 0.5em 0 1em;
}
.DateRangePickerInput {
    background-color: #f9f9f9 !important;
}
.DateInput {
    background-color: #f9f9f9 !important;
}
.DayPicker {
    td {
        padding: 0;
        text-align: center;
    }
    button {
        display: block;
        flex-direction: row;
        text-align: center;
        margin: 0;
        min-height: 0;
    }
}

// Override default react-dates variables with styleguide colors (https://github.com/airbnb/react-dates#theming)
$react-dates-color-primary: $white-on-green;
$react-dates-color-primary-shade-1: lighten($white-on-green, 15%);
$react-dates-color-primary-shade-2: lighten($white-on-green, 30%);
$react-dates-color-primary-shade-3: lighten($white-on-green, 45%);
$react-dates-color-primary-shade-4: lighten($white-on-green, 60%);
$react-dates-color-secondary: $green-on-white;
$react-dates-color-highlighted: lighten($white-on-green, 45%);
$react-dates-color-focus: $light-grey;
$react-dates-color-text-focus: $green-on-white;
@import '../../../node_modules/react-dates/css/variables.scss';
@import '../../../node_modules/react-dates/css/styles.scss';

.DayPickerKeyboardShortcuts__show--bottom-right {
    &:hover {
        border-right: 33px solid lighten($white-on-green, 15%);
    }
}

// Tabs
.nav-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        margin-bottom: -1px;
        a {
            display: block;
            padding: 10px 15px;
            margin-right: 5px;
            text-decoration: none;
            background: $white;
            border: 1px solid $grey;
            &:hover {
                background: $light-grey;
            }
            &.active {
                border-bottom-color: transparent;
                font-weight: 700;
            }
        }
    }
}

.csv-download--btn {
    text-align: center;
}

// Filepond
.filepond--panel-root {
    border-radius: 0 !important;
    background-color: transparent;
    border: 1px solid #2c3340;
}

.filepond--file-action-button {
    min-height: 0;
    cursor: pointer;
}

.filepond--root {
    height: 5em !important;
    max-height: 10em;
    width: 30em;
    margin-left: auto;
    margin-right: auto;
    font-size: 2rem !important;
}

.filepond--drop-label {
    font-size: 1.1em;
}

.filepond--action-remove-item {
    flex-direction: row;
}

.filepond--action-retry-item-processing {
    flex-direction: row;
}

.filepond--action-revert-item-processing {
    flex-direction: row;
}

.filepond--item {
    margin: 0 !important;
}