/**
 * Grid setup
 */

.section {
    max-width: 978px;
    margin: 0 auto;
    padding: 0 5%;
    clear: both;
    @include mq('desktop') {
        padding: 0;
    }
}

@include mq('mobile') {
    .col {
        display: block;
        float: left;
        margin-left: 3.06%;
    }
}

.col:first-child, :not(.col) + .col {
    margin-left: 0;
}

.row {
    clear: both;
}

.no-margin {
    margin: 0;
}

.span_12_of_12, .span_11_of_12, .span_10_of_12, .span_9_of_12, .span_8_of_12, .span_7_of_12, .span_6_of_12, .span_5_of_12, .span_4_of_12, .span_3_of_12, .span_2_of_12, .span_1_of_12 {
    width: 100%;
}

.push_1, .push_2, .push_3, .push_4, .push_5, .push_6, .push_7, .push_8, .push_9, .push_10, .push_11, .push_12 {
    padding-left: 0;
}

@include mq('mobile') {

    .span_12_of_12 {
        width: 100%;
        margin-left: 0;
    }

    .span_11_of_12 {
        width: 91.408%;
    }

    .span_10_of_12 {
        width: 82.82%;
    }

    .span_9_of_12 {
        width: 74.232%;
    }

    .span_8_of_12 {
        width: 65.644%;
    }

    .span_7_of_12 {
        width: 57.056%;
    }

    .span_6_of_12 {
        width: 48.468%;
    }

    .span_5_of_12 {
        width: 39.88%;
    }

    .span_4_of_12 {
        width: 31.292%;
    }

    .span_3_of_12 {
        width: 22.704%;
    }

    .span_2_of_12 {
        width: 14.116%;
    }

    .span_1_of_12 {
        width: 5.528%;
    }

    .push_1 {
        padding-left: 8.588%;
    }

    .push_2 {
        padding-left: 17.176%;
    }

    .push_3 {
        padding-left: 25.764%;
    }

    .push_4 {
        padding-left: 34.352%;
    }

    .push_5 {
        padding-left: 42.94%;
    }

    .push_6 {
        padding-left: 51.528%;
    }

    .push_7 {
        padding-left: 60.116%;
    }

    .push_8 {
        padding-left: 68.704%;
    }

    .push_9 {
        padding-left: 77.292%;
    }

    .push_10 {
        padding-left: 85.88%;
    }

    .push_11 {
        padding-left: 94.468%;
    }

}

.section__form {
    .span_10_of_12.push_1 {
        width: 100%;
    }
    .push_1 {
        padding-left: 0;
    }
    @include mq('desktop') {
        .span_10_of_12.push_1 {
            width: 82.82%;
        }
        .push_1 {
            padding-left: 8.588%;
        }
    }
    @include mq('mobile') {
        .span_6_of_12 {
            &:nth-of-type(odd) {
                margin-left: 0;
                clear: both;
            }
        }
    }
}
