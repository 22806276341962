/* Colours */
$white: #fff;
$black: #000;
$green-on-black: #93aa1f;
$green-on-white: #525f11;
$white-on-green: #899c1c;

$light-grey: #f9f9f9;
$grey: #ddd;
$mid-grey: #999;
$dark-grey: #555;

/* Fonts */
$gotham: 'Gotham SSm A', 'Gotham SSm B', Arial, Helvetica, sans-serif;

/* Media queries */
$breakpoints: (
    "sm-mobile": 321px,
    "mobile": 575px,
    "tablet": 769px,
    "desktop": 1020px
);
