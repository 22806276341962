@mixin clearfix {
    &:after {
        clear: both;
    }
    &:before,
    &:after {
        display: table;
        content: ' ';
    }
}

@mixin border-box {
    box-sizing: border-box;
}

// Font size for Rem font sizing (with px fallback)
@mixin font-size($sizeValue: 1.6) {
    font-size: $sizeValue + px;
    font-size: ($sizeValue / 10) + rem;
}

// Media queries
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}